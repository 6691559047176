define("ember-countries/utils/states-properties", ["exports", "ember-countries/utils/helpers", "ember-countries/utils/countries-properties", "ember-countries/utils/states-lists"], function (_exports, _helpers, _countriesProperties, _statesLists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countryContainsState = countryContainsState;
  _exports.getState = getState;
  _exports.getStatesForCountry = getStatesForCountry;
  _exports.isCustomsDeclarationRequiredInUS = isCustomsDeclarationRequiredInUS;
  var A = Ember.A,
    isNone = Ember.isNone;
  var indexedByIso2;
  function getState(country, value) {
    var valueFormat = (0, _helpers.getFormat)(value);
    if (valueFormat === null || valueFormat !== 'iso2') {
      return;
    }
    var STATES_LIST = getStatesForCountry(country);
    if (isNone(STATES_LIST)) {
      return;
    }
    var listIndexed = _getStatesListIndexed(STATES_LIST, valueFormat);
    var selectedState = listIndexed[value.toUpperCase()];
    if (!isNone(selectedState)) {
      return selectedState;
    }
  }
  function getStatesForCountry(value) {
    if (!(0, _countriesProperties.isCountryWithState)(value)) {
      return;
    }
    var country = (0, _countriesProperties.getCountry)(value);
    return _statesLists.STATES_BY_COUNTRIES[country.iso2];
  }
  function isCustomsDeclarationRequiredInUS(stateFrom, stateTo) {
    if (stateFrom === stateTo) {
      return false;
    }
    return A([stateFrom, stateTo]).any(function (state) {
      return _statesLists.US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST.indexOf(state) !== -1;
    });
  }
  function countryContainsState(country, state) {
    var STATES_LIST = getStatesForCountry(country);
    if (isNone(STATES_LIST)) {
      return false;
    }
    return STATES_LIST.mapBy('iso2').indexOf(state) !== -1;
  }
  function _getStatesListIndexed(LIST, code) {
    var index = {};
    if (code === 'iso2') {
      index = (0, _helpers.buildIndex)(LIST, code, indexedByIso2);
      indexedByIso2 = index;
    }
    return index;
  }
});