define("ember-burger-menu/-private/state", ["exports", "ember-burger-menu/animations"], function (_exports, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    open: false,
    locked: false,
    width: 300,
    position: 'left',
    animation: 'slide',
    minSwipeDistance: 150,
    maxSwipeTime: 300,
    itemAnimation: null,
    customAnimation: null,
    styles: Ember.computed('animation', 'itemAnimation', 'customAnimation', function () {
      var animation = this.get('animation');
      var itemAnimation = this.get('itemAnimation');
      var customAnimation = this.get('customAnimation');
      return (0, _animations.default)(customAnimation || animation, itemAnimation).create();
    }).readOnly(),
    actions: Ember.computed(function () {
      var _this = this;
      return {
        open: function open() {
          return !_this.get('locked') && _this.set('open', true);
        },
        close: function close() {
          return !_this.get('locked') && _this.set('open', false);
        },
        toggle: function toggle() {
          return !_this.get('locked') && _this.toggleProperty('open');
        }
      };
    }).readOnly()
  });
  _exports.default = _default;
});