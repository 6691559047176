define("ember-cli-number-abbr/helpers/number-abbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberAbbr = numberAbbr;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function log10(x) {
    if (Math.log10) {
      return Math.log10(x);
    } else {
      return Math.log(x) / Math.LN10;
    }
  }
  function getDecimalPlaces(adjustedNumber, maxSignificantPlaces) {
    var baseLength = Math.ceil(log10(adjustedNumber));
    var decimalPlaces = maxSignificantPlaces - (baseLength > 0 ? baseLength : 0);
    return decimalPlaces > 0 ? decimalPlaces : 0;
  }
  function numberAbbr(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 5),
      _ref2$ = _ref2[0],
      number = _ref2$ === void 0 ? 0 : _ref2$,
      _ref2$2 = _ref2[1],
      delimiter = _ref2$2 === void 0 ? '.' : _ref2$2,
      _ref2$3 = _ref2[2],
      maxSignificantPlaces = _ref2$3 === void 0 ? 3 : _ref2$3,
      _ref2$4 = _ref2[3],
      padding = _ref2$4 === void 0 ? false : _ref2$4,
      _ref2$5 = _ref2[4],
      abbreviations = _ref2$5 === void 0 ? null : _ref2$5;
    // jshint ignore:line

    var symbols = abbreviations || Ember.A(['', 'K', 'M', 'B', 'T', 'Qua', 'Qui', 'Sex', 'Sep', 'Oct', 'Non', 'Dec']);
    var results = symbols.map(function (symbol, index) {
      var adjustedNumber = number / Math.pow(10, index * 3);
      var decimalPlaces = getDecimalPlaces(adjustedNumber, maxSignificantPlaces);
      var roundedNumber = adjustedNumber.toFixed(decimalPlaces);
      var strippedNumber = padding ? roundedNumber : roundedNumber.replace(/(\.0+$)/, '');
      strippedNumber = strippedNumber.replace(/\./, delimiter);
      return strippedNumber + symbol;
    });
    var result = Ember.A(results).find(function (result, index) {
      return number.toString().length <= (index + 1) * 3;
    });
    return result;
  }
  var _default = Ember.Helper.helper(numberAbbr);
  _exports.default = _default;
});