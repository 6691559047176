define("ember-burger-menu/components/burger-menu", ["exports", "ember-burger-menu/templates/components/burger-menu", "ember-burger-menu/computed/style-for", "ember-burger-menu/mixins/swipe-support", "ember-burger-menu/-private/state", "ember-lifeline/mixins/dom", "ember-burger-menu/utils/is-fastboot", "ember-burger-menu/utils/closest", "ember-jquery-legacy"], function (_exports, _burgerMenu, _styleFor, _swipeSupport, _state, _dom, _isFastboot, _closest, _emberJqueryLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_dom.default, _swipeSupport.default, {
    layout: _burgerMenu.default,
    classNames: ['ember-burger-menu'],
    classNameBindings: ['open:is-open', 'translucentOverlay', 'animationClass', 'itemAnimationClass', 'position'],
    attributeBindings: ['style'],
    translucentOverlay: true,
    dismissOnClick: true,
    dismissOnEsc: true,
    gesturesEnabled: true,
    state: Ember.computed(function () {
      return _state.default.create();
    }).readOnly(),
    open: Ember.computed.alias('state.open'),
    locked: Ember.computed.alias('state.locked'),
    position: Ember.computed.alias('state.position'),
    width: Ember.computed.alias('state.width'),
    animation: Ember.computed.alias('state.animation'),
    itemAnimation: Ember.computed.alias('state.itemAnimation'),
    customAnimation: Ember.computed.alias('state.customAnimation'),
    style: (0, _styleFor.default)('container').readOnly(),
    animationClass: Ember.computed('state.styles.animation', function () {
      var animation = this.get('state.styles.animation');
      return animation ? "bm--".concat(animation) : '';
    }).readOnly(),
    itemAnimationClass: Ember.computed('state.styles.itemAnimation', function () {
      var itemAnimation = this.get('state.styles.itemAnimation');
      return itemAnimation ? "bm-item--".concat(itemAnimation) : '';
    }).readOnly(),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this._setupEventsTimer);
    },
    setupEvents: Ember.on('didReceiveAttrs', Ember.observer('open', 'locked', function () {
      var _this = this;
      if ((0, _isFastboot.default)()) {
        return;
      }
      Ember.run.later(function () {
        var methodName = _this.get('open') && !_this.get('locked') ? '_setupEvents' : '_teardownEvents';
        _this._setupEventsTimer = Ember.run.scheduleOnce('afterRender', _this, methodName);
      }, 0);
    })),
    _setupEvents: function _setupEvents() {
      if (this.get('dismissOnClick')) {
        this.addEventListener(document.body, "click", this.onClick);
        this.addEventListener(document.body, "touchstart", this.onClick);
      }
      if (this.get('dismissOnEsc')) {
        this.addEventListener(window, "keyup", this.onKeyup);
      }
    },
    _teardownEvents: function _teardownEvents() {
      this.removeEventListener(document.body, "click", this.onClick);
      this.removeEventListener(document.body, "touchstart", this.onClick);
      this.removeEventListener(window, "keyup", this.onKeyup);
    },
    onClick: function onClick(e) {
      var nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      var elementId = this.get('elementId');
      // Close the menu if clicked outside of it
      if (!(0, _closest.default)(nativeEvent.target, "#".concat(elementId, " .bm-menu"), true)) {
        this.get('state.actions').close();
      }
    },
    onKeyup: function onKeyup(e) {
      var nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      if (nativeEvent.keyCode === 27) {
        this.get('state.actions').close();
      }
    },
    onSwipe: function onSwipe(direction, target) {
      var position = this.get('position');
      var open = this.get('open');
      var gesturesEnabled = this.get('gesturesEnabled');
      var isMenuSwipe = (0, _closest.default)(target, '.bm-menu', true);
      if (!gesturesEnabled) {
        return;
      }
      if (open && isMenuSwipe && position === direction) {
        this.get('state.actions').close();
      } else if (!open && position !== direction) {
        this.get('state.actions').open();
      }
    }
  });
  _exports.default = _default;
});