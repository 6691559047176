define("ember-inflector/lib/system/string", ["exports", "ember-inflector/lib/system/inflector"], function (_exports, _inflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pluralize = pluralize;
  _exports.singularize = singularize;
  function pluralize(word) {
    return _inflector.default.inflector.pluralize(word);
  }
  function singularize(word) {
    return _inflector.default.inflector.singularize(word);
  }
});