define("ember-metrics/utils/can-use-dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  var _default = canUseDOM;
  _exports.default = _default;
});