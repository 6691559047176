define("ember-new-relic/instance-initializers/new-relic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var _window = window,
      NREUM = _window.NREUM;
    if (!NREUM) {
      return;
    }
    function mustIgnoreError(error) {
      /* Ember 2.X seems to not catch `TransitionAborted` errors caused by
      regular redirects. We don't want these errors to show up in NewRelic
      so we have to filter them ourselfs.
       See https://github.com/emberjs/ember.js/issues/12505
      */

      if (Ember.isNone(error)) {
        return false;
      }
      return error.name === 'TransitionAborted';
    }
    function handleError(error) {
      if (mustIgnoreError(error)) {
        return;
      }
      try {
        NREUM.noticeError(error);
      } catch (e) {
        // Ignore
      }
      console.error(error); // eslint-disable-line  no-console
    }

    function generateError(cause, stack) {
      var error = new Error(cause);
      error.stack = stack;
      return error;
    }
    Ember.onerror = handleError;
    Ember.RSVP.on('error', handleError);
    if (Ember.Logger) {
      Ember.Logger.error = function () {
        for (var _len = arguments.length, messages = new Array(_len), _key = 0; _key < _len; _key++) {
          messages[_key] = arguments[_key];
        }
        handleError(generateError(messages.join(' ')));
      };
    }
  }
  var _default = {
    name: 'new-relic',
    initialize: initialize
  };
  _exports.default = _default;
});