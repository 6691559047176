define("ember-burger-menu/utils/is-fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFastboot;
  /* global self */

  function isFastboot() {
    return !self.document;
  }
});