define("ember-master-tab/services/master-tab-factory", ["exports", "ember-master-tab/utils", "ember-master-tab/consts"], function (_exports, _utils, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  /**
   * Checks whether the current tab is the master tab.
   */
  function isMasterTab() {
    return localStorage[_consts.tabIdKey] === _consts.tabId;
  }

  /** The service factory. */
  var _default = Ember.Service.extend(Ember.Evented, {
    /** Contains current lock names that will be deleted during the 'beforeunload' window event. */
    lockNames: Ember.A(),
    resolve: null,
    contestTimeout: null,
    /**
     * Sets up listeners on the 'storage' and 'beforeunload' window events.
     * Returns a promise that resolves immediately if this or another tab is the master tab and that
     * tab is currently present. In case the master tab crashed and a new tab is opened, this promise
     * will resolve after a short delay while it invalidates the master tab.
     */
    setup: function setup() {
      var _this = this;
      var storageHandler = function storageHandler(e) {
        switch (e.key) {
          case _consts.tabIdKey:
            {
              var newTabId = e.newValue;
              if (newTabId === null) {
                (0, _utils.debug)('Master tab currently being contested.');
                localStorage[_consts.shouldInvalidateMasterTabKey] = false;
                _this.registerAsMasterTab();
              } else {
                if (_this.get('isMasterTab') && e.oldValue !== null && _consts.tabId !== newTabId) {
                  (0, _utils.debug)('Lost master tab status. Probably race condition related.');
                  Ember.run(function () {
                    _this.set('isMasterTab', false);
                    _this.trigger('isMasterTab', false);
                  });
                }
              }
              break;
            }
          case _consts.shouldInvalidateMasterTabKey:
            {
              var shouldInvalidateMasterTab = eval(e.newValue);
              var _isMasterTab = isMasterTab();
              if (shouldInvalidateMasterTab && _isMasterTab) {
                localStorage[_consts.shouldInvalidateMasterTabKey] = false;
                (0, _utils.debug)('Invalidation of master tab avoided.');
              } else if (!shouldInvalidateMasterTab && !_isMasterTab) {
                if (_this.contestTimeout !== null) {
                  clearTimeout(_this.contestTimeout);
                  _this.contestTimeout = null;
                  if (_this.resolve !== null) {
                    _this.resolve();
                    _this.resolve = null;
                  }
                  (0, _utils.debug)('Invalidation of master tab aborted.');
                }
              }
              break;
            }
        }
      };
      window.addEventListener('storage', storageHandler);
      window.addEventListener('beforeunload', function () {
        window.removeEventListener('storage', storageHandler);
        _this.lockNames.forEach(function (l) {
          delete localStorage[l];
          (0, _utils.debug)("Deleted lock [".concat(l, "]."));
        });
        if (isMasterTab()) {
          delete localStorage[_consts.tabIdKey];
          (0, _utils.debug)('Unregistered as master tab. ');
        }
      });
      return this.contestMasterTab();
    },
    isMasterTab: false,
    /** Tries to register as the master tab if there is no current master tab registered. */registerAsMasterTab: function registerAsMasterTab() {
      var _this2 = this;
      var success = false;
      if (isMasterTab()) {
        success = true;
      } else {
        if (typeof localStorage[_consts.tabIdKey] === 'undefined') {
          localStorage[_consts.tabIdKey] = _consts.tabId;
          localStorage[_consts.shouldInvalidateMasterTabKey] = false;
          success = true;
        }
        (0, _utils.debug)("Trying to register as master tab... ".concat(success ? 'SUCCESS' : 'FAILED', "."));
      }
      Ember.run(function () {
        _this2.set('isMasterTab', success);
        _this2.trigger('isMasterTab', success);
      });
      return success;
    },
    /**
     * Returns a promise which attempts to contest the master tab.
     */
    contestMasterTab: function contestMasterTab() {
      var _this3 = this;
      return new Ember.RSVP.Promise(function (resolve) {
        if (!_this3.registerAsMasterTab()) {
          (0, _utils.debug)('Trying to invalidate master tab.');
          _this3.resolve = resolve;
          _this3.contestTimeout = setTimeout(function () {
            var shouldInvalidateMasterTab = eval(localStorage[_consts.shouldInvalidateMasterTabKey]);
            if (shouldInvalidateMasterTab) {
              localStorage[_consts.shouldInvalidateMasterTabKey] = false;
              delete localStorage[_consts.tabIdKey];
              _this3.registerAsMasterTab();
            }
            resolve();
          }, 500);
          localStorage[_consts.shouldInvalidateMasterTabKey] = true;
        } else {
          resolve();
        }
      });
    },
    /**
     * Runs the provided function if this is the master tab. If this is not the current tab, run
     * the function provided to 'else()'.
     */
    run: function run(func) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (_typeof(options) !== 'object') {
        throw 'Options must be an object.';
      }
      var finalOptions = Ember.assign({
        force: false
      }, options);
      var _isMasterTab = isMasterTab();
      if (_isMasterTab || finalOptions.force) {
        func();
      }
      return {
        else: function _else(func) {
          if (!_isMasterTab && !finalOptions.force) {
            func();
          }
        }
      };
    },
    /**
     * Runs the provided function (which should return a Promise) if this is the master tab.
     * It creates a lock which is freed once the Promise is resolved or rejected.
     * If this is not the master tab, run the function provided to 'wait()'. If there is no
     * lock present currently, the function runs immediately. If there is, it will run once
     * the promise on the master tab resolves or rejects.
     */
    lock: function lock(lockName, func) {
      var _this4 = this;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      if (_typeof(options) !== 'object') {
        throw 'Options must be an object.';
      }
      var finalOptions = Ember.assign({
        force: false,
        waitNext: true,
        waitNextDelay: 1000
      }, options);
      var lockNameKey = "".concat(_consts.namespace, "lock:").concat(lockName);
      var lockResultKey = "".concat(lockNameKey, ":result");
      var lockResultTypeKey = "".concat(lockNameKey, ":result-type");
      var isLocked = typeof localStorage[lockNameKey] !== 'undefined';
      var _isMasterTab = isMasterTab();
      if ((_isMasterTab || finalOptions.force) && !isLocked) {
        localStorage[lockNameKey] = true;
        delete localStorage[lockResultKey];
        delete localStorage[lockResultTypeKey];
        if (this.lockNames.indexOf(lockNameKey) === -1) {
          this.lockNames.push(lockNameKey);
        }
        var p = func();
        if (!p || !p.then) {
          throw 'The function argument must return a thennable object.';
        }
        var callback = function callback(type, result) {
          localStorage[lockResultTypeKey] = type;
          localStorage[lockResultKey] = result;
          delete localStorage[lockNameKey];
          var index = _this4.lockNames.indexOf(lockNameKey);
          if (index > -1) {
            _this4.lockNames.splice(index, 1);
          }
        };
        p.then(function (result) {
          return callback('success', result);
        }, function (result) {
          return callback('failure', result);
        });
      }
      return {
        wait: function wait(success) {
          var failure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
          if (!_isMasterTab && !finalOptions.force || isLocked) {
            var callCallback = function callCallback(waited) {
              var resultType = localStorage[lockResultTypeKey];
              var func = resultType === 'success' ? success : failure;
              var result = localStorage[lockResultKey];
              if (func !== null) {
                func(result, waited);
              }
            };
            if (isLocked || finalOptions.waitNext) {
              var handler = function handler(e) {
                if (e.key === lockNameKey && e.newValue === null) {
                  window.removeEventListener('storage', handler);
                  callCallback(true);
                }
              };
              window.addEventListener('storage', handler);
              if (finalOptions.waitNext) {
                setTimeout(function () {
                  window.removeEventListener('storage', handler);
                  callCallback(true);
                }, finalOptions.waitNextDelay);
              }
            } else {
              callCallback(false);
            }
          }
        }
      };
    }
  });
  _exports.default = _default;
});