define("ember-cli-jwt-decode/index", ["exports", "ember-cli-jwt-decode/-base64-url-decode"], function (_exports, _base64UrlDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jwt_decode = jwt_decode;
  //from: https://github.com/auth0/jwt-decode/blob/master/lib/index.js

  function jwt_decode(token) {
    if (typeof token !== 'string') {
      throw new Error('Invalid token specified');
    }
    var tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      throw new Error('Invalid token specified');
    }
    return JSON.parse((0, _base64UrlDecode.base64UrlDecode)(tokenParts[1]));
  }
  var _default = jwt_decode;
  _exports.default = _default;
});