define("ember-wormhole/templates/components/ember-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nlW9Bf4i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,1],[[35,0]],null]],[18,1,null],[1,[30,[36,1],[[35,2]],null]]],\"hasEval\":false,\"upvars\":[\"_wormholeHeadNode\",\"unbound\",\"_wormholeTailNode\"]}",
    "meta": {
      "moduleName": "ember-wormhole/templates/components/ember-wormhole.hbs"
    }
  });
  _exports.default = _default;
});