define("ember-burger-menu/animations/menu-item/push", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    itemAnimation: 'push',
    menuItem: function menuItem(open, width, right, index) {
      return {
        transform: open ? '' : right ? "translate3d(".concat((index + 1) * 500, "px, 0, 0)") : "translate3d(-".concat((index + 1) * 500, "px, 0, 0)")
      };
    }
  });
  _exports.default = _default;
});