define("ember-burger-menu/mixins/swipe-support", ["exports", "ember-jquery-legacy"], function (_exports, _emberJqueryLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var meta;
  var _default = Ember.Mixin.create({
    minSwipeDistance: Ember.computed.alias('state.minSwipeDistance'),
    maxSwipeTime: Ember.computed.alias('state.maxSwipeTime'),
    onSwipe: function onSwipe() {} /* direction, target */,
    touchStart: function touchStart(e) {
      var nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      this._super.apply(this, arguments);

      // jscs:disable
      var touch = nativeEvent.touches[0];
      // jscs:enable

      meta = {
        target: e.target,
        start: {
          x: touch.pageX,
          y: touch.pageY,
          time: new Date().getTime()
        }
      };
    },
    touchMove: function touchMove(e) {
      var nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      this._super.apply(this, arguments);

      // jscs:disable
      var touch = nativeEvent.touches[0];
      // jscs:enable

      meta.differences = {
        x: touch.pageX - meta.start.x,
        y: touch.pageY - meta.start.y
      };

      // Compute swipe direction
      if (Ember.isNone(meta.isHorizontal)) {
        meta.isHorizontal = Math.abs(meta.differences.x) > Math.abs(meta.differences.y);
      }

      // A valid swipe event uses only one finger
      if (nativeEvent.touches.length > 1) {
        meta.isInvalid = true;
      }
    },
    touchEnd: function touchEnd() {
      this._super.apply(this, arguments);
      var minSwipeDistance = this.get('minSwipeDistance');
      var maxSwipeTime = this.get('maxSwipeTime');
      var elapsedTime = new Date().getTime() - meta.start.time;
      if (meta.isHorizontal && !meta.isInvalid && Math.abs(meta.differences.x) >= minSwipeDistance && elapsedTime <= maxSwipeTime) {
        this.onSwipe(meta.differences.x > 0 ? 'right' : 'left', meta.target);
      }
    }
  });
  _exports.default = _default;
});