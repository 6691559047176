define("ember-cli-pagination/remote/controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    queryParams: ["page", "perPage"],
    page: Ember.computed.alias("model.page"),
    totalPages: Ember.computed.alias("model.totalPages"),
    pagedContent: Ember.computed.alias("model")
  });
  _exports.default = _default;
});