define("ember-burger-menu/animations/menu-item/stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    itemAnimation: 'stack',
    menuItem: function menuItem(open, width, right, index) {
      return {
        transform: open ? '' : "translate3d(0, ".concat((index + 1) * 500, "px, 0)")
      };
    }
  });
  _exports.default = _default;
});