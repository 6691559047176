define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8uRl1zNL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,18],null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[35,13,[\"uniqueId\"]],[35,13,[\"isOpen\"]],[35,13,[\"disabled\"]],[35,13,[\"actions\"]],[30,[36,15],[[35,17]],[[\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[30,[36,1],[[35,13]],null],[30,[36,1],[[35,12]],null],[30,[36,16],[[32,0],\"handleFocus\"],null],[30,[36,1],[[35,11]],null],[30,[36,1],[[35,8]],null]]]],[30,[36,15],[[35,14]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[30,[36,1],[[35,13]],null],[30,[36,1],[[35,12]],null],[30,[36,1],[[35,11]],null],[30,[36,1],[[35,10]],null],[30,[36,1],[[35,9]],null],[30,[36,1],[[35,8]],null],[30,[36,1],[[35,7]],null],[30,[36,1],[[35,6]],null],[30,[36,1],[[35,5]],null],[30,[36,1],[[35,4]],null],[30,[36,1],[[35,3]],null],[30,[36,1],[[35,2]],null],[30,[36,1],[[35,0]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"otherStyles\",\"readonly\",\"height\",\"width\",\"right\",\"left\",\"top\",\"destination\",\"vPosition\",\"rootEventType\",\"preventScroll\",\"renderInPlace\",\"hPosition\",\"publicAPI\",\"contentComponent\",\"component\",\"action\",\"triggerComponent\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });
  _exports.default = _default;
});