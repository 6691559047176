define("ember-changeset/utils/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includes;
  function includes /*:: <T> */(arr /*: Array<T> */) /*: boolean */{
    (false && !(Ember.typeOf(arr) === 'array') && Ember.assert('must be array', Ember.typeOf(arr) === 'array'));
    var wrapped = Ember.A(arr);
    var inclusionFn = wrapped.includes || wrapped.contains;
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return inclusionFn.apply(arr, args);
  }
});