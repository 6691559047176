define("ember-burger-menu/templates/components/bm-outlet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xxSew9ZB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,1,[30,[36,1],[[35,0],\"-outlet-menu\"],null]],[14,0,\"bm-menu-container\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"bm-content\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"containerId\",\"concat\"]}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-outlet.hbs"
    }
  });
  _exports.default = _default;
});