define("ember-radio-button/templates/components/labeled-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ItMaYnOx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,7],null,[[\"radioClass\",\"radioId\",\"changed\",\"disabled\",\"groupValue\",\"name\",\"required\",\"value\"],[[35,6],[35,5],\"innerRadioChanged\",[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"required\",\"name\",\"groupValue\",\"disabled\",\"radioId\",\"radioClass\",\"radio-button\"]}",
    "meta": {
      "moduleName": "ember-radio-button/templates/components/labeled-radio-button.hbs"
    }
  });
  _exports.default = _default;
});