define("ember-inputmask/components/one-way-email-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * `{{one-way-email-mask}}` component.
   *
   * Displays an input that masks to email 
   */
  var _default = _oneWayInputMask.default.extend({
    /**
     * @override
     */
    mask: 'email'
  });
  _exports.default = _default;
});