define("ember-progress-bar/components/ember-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function deepObjectAssign(object) {
    Object.keys(object).forEach(function (key) {
      if (Ember.typeOf(object[key]) === 'object') {
        object[key] = deepObjectAssign(object[key]);
      }
    });
    return Ember.assign({}, object);
  }
  var _default = Ember.Component.extend({
    classNames: ['ember-progress-bar'],
    hook: 'ember_progress_bar',
    onAnimationComplete: null,
    shape: 'Line',
    setProgress: null,
    defaultStep: function defaultStep(state, bar) {
      bar.setText((bar.value() * 100).toFixed(0));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var shape = Ember.get(this, 'shape');
      var options = deepObjectAssign(Ember.get(this, 'options') || {});
      if (Ember.get(this, 'useDefaultStep')) {
        Ember.set(options, 'step', Ember.get(this, 'defaultStep'));
      }
      var progressBar = new ProgressBar[shape](this.element, options);
      Ember.set(this, 'progressBar', progressBar);
    },
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      var _Ember$getProperties = Ember.getProperties(this, 'progressBar', 'progress', 'setProgress'),
        progressBar = _Ember$getProperties.progressBar,
        progress = _Ember$getProperties.progress,
        setProgress = _Ember$getProperties.setProgress;
      if (Ember.isPresent(setProgress)) {
        progressBar.set(setProgress);
      }
      Ember.get(this, 'progressBar').animate(progress, function () {
        if (_this.get('onAnimationComplete')) _this.get('onAnimationComplete')();
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, 'progressBar').destroy();
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});