define("ember-burger-menu/utils/css-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cssStringify;
  var PREFIXES = ['webkit'];
  var PREFIXED_PROPS = ['transition', 'transition-timing-function', 'transition-property', 'transition-speed', 'transition-delay', 'transform', 'transform-style', 'transform-origin', 'perspective', 'perspective-origin'];
  function cssStringify() {
    var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return Ember.String.htmlSafe(Object.keys(hash).reduce(function (css, key) {
      var value = hash[key];
      if (!Ember.isEmpty(value)) {
        css = css.concat(buildProp(key, value));
      }
      return css;
    }, []).join('; '));
  }
  function buildProp(key, value) {
    key = Ember.String.dasherize(key);
    var css = ["".concat(key, ": ").concat(value)];
    if (PREFIXED_PROPS.indexOf(key) > -1) {
      PREFIXES.forEach(function (p) {
        css.push("-".concat(p, "-").concat(key, ": ").concat(value));
      });
    }
    return css;
  }
});