define("ember-burger-menu/animations/index", ["exports", "ember-require-module"], function (_exports, _emberRequireModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAnimation;
  var ANIMATION_PATH = 'ember-burger-menu/animations';
  var ANIMATION_ITEM_PATH = "".concat(ANIMATION_PATH, "/menu-item");
  function getAnimation(animation, itemAnimation) {
    var AnimationClass;
    if (Ember.typeOf(animation) === 'class' && animation.__isAnimation__) {
      AnimationClass = animation;
    } else {
      AnimationClass = (0, _emberRequireModule.default)("".concat(ANIMATION_PATH, "/").concat(animation));
      (false && !(AnimationClass) && Ember.assert("The animation '".concat(animation, "' could not be found."), AnimationClass));
    }
    if (!Ember.isEmpty(itemAnimation)) {
      var MenuItemMixin = (0, _emberRequireModule.default)("".concat(ANIMATION_ITEM_PATH, "/").concat(itemAnimation));
      (false && !(MenuItemMixin) && Ember.assert("The item animation '".concat(itemAnimation, "' could not be found."), MenuItemMixin));
      return AnimationClass.extend(MenuItemMixin);
    }
    return AnimationClass;
  }
});