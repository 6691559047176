define("ember-inflector/index", ["exports", "ember-inflector/lib/system", "ember-inflector/lib/ext/string"], function (_exports, _system, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "defaultRules", {
    enumerable: true,
    get: function get() {
      return _system.defaultRules;
    }
  });
  Object.defineProperty(_exports, "pluralize", {
    enumerable: true,
    get: function get() {
      return _system.pluralize;
    }
  });
  Object.defineProperty(_exports, "singularize", {
    enumerable: true,
    get: function get() {
      return _system.singularize;
    }
  });
  _system.Inflector.defaultRules = _system.defaultRules;
  Ember.Inflector = _system.Inflector;
  Ember.String.pluralize = _system.pluralize;
  Ember.String.singularize = _system.singularize;
  var _default = _system.Inflector;
  _exports.default = _default;
  if (typeof define !== 'undefined' && define.amd) {
    define('ember-inflector', ['exports'], function (__exports__) {
      __exports__['default'] = _system.Inflector;
      __exports__.pluralize = _system.pluralize;
      __exports__.singularize = _system.singularize;
      return __exports__;
    });
  } else if (typeof module !== 'undefined' && module['exports']) {
    module['exports'] = _system.Inflector;
    _system.Inflector.singularize = _system.singularize;
    _system.Inflector.pluralize = _system.pluralize;
  }
});