define("ember-burger-menu/animations/slide-reverse", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'slide-reverse',
    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? "translate3d(-".concat(width, "px, 0, 0)") : "translate3d(".concat(width, "px, 0, 0)") : ''
      };
    }
  });
  _exports.default = _default;
});