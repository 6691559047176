define("ember-filestack/services/filestack", ["exports", "filestack"], function (_exports, _filestack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    alias = Ember.computed.alias,
    getOwner = Ember.getOwner,
    on = Ember.on,
    later = Ember.run.later,
    Promise = Ember.RSVP.Promise;
  var _default = Ember.Service.extend({
    promise: null,
    instance: null,
    _initFilestack: on('init', function () {
      var _this = this;
      var _isPromiseFulfilled = false;
      this.set('promise', new Promise(function (resolve, reject) {
        var key = _this.get('key');
        if (!key) {
          reject(new Error("Filestack key not found."));
          return;
        }
        if (_filestack.default && _filestack.default.init) {
          var instance = _filestack.default.init(key);
          if (!(_this.isDestroyed || _this.isDestroying)) {
            _this.set('instance', instance);
          }
          resolve(instance);
          _isPromiseFulfilled = true;
        } else {
          reject(new Error("Filestack not found."));
          return;
        }
        later(_this, function () {
          if (!_isPromiseFulfilled) {
            reject.call(null, new Error('Filestack load timeout.'));
          }
        }, _this.get('loadTimeout'));
      }));
    }),
    config: computed(function () {
      return getOwner(this).resolveRegistration('config:environment');
    }),
    key: alias('config.filestackKey'),
    loadTimeout: computed('config.filestackLoadTimeout', function () {
      return this.get('config.filestackLoadTimeout') || 10000;
    })
  });
  _exports.default = _default;
});