define("ember-one-way-controls/components/one-way-select/option", ["exports", "ember-one-way-controls/templates/components/one-way-select/option"], function (_exports, _option) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;
  var _default = Component.extend({
    layout: _option.default,
    tagName: ''
  });
  _exports.default = _default;
});