define("ember-inputmask/components/email-input", ["exports", "ember-inputmask/components/input-mask"], function (_exports, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * `{{email-input}}` component.
   *
   * Displays an input that masks email addresses.
   */
  var _default = _inputMask.default.extend({
    oldComponent: '{{email-input}}',
    newComponent: '{{one-way-email-mask}}',
    mask: 'email'
  });
  _exports.default = _default;
});