define("ember-burger-menu/animations/base", ["exports", "ember-burger-menu/utils/css-stringify"], function (_exports, _cssStringify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Animation = Ember.Object.extend({
    animation: null,
    itemAnimation: null,
    container: function container() {
      return {};
    } /* open, width, right */,
    outlet: function outlet() {
      return {};
    } /* open, width, right */,
    menu: function menu() {
      return {};
    } /* open, width, right */,
    menuItem: function menuItem() {
      return {};
    } /* open, width, right, index */,
    generateCSSFor: function generateCSSFor(type, _ref) {
      var open = _ref.open,
        width = _ref.width,
        position = _ref.position,
        index = _ref.index;
      var result;
      (false && !(typeof width === 'number') && Ember.assert('Width must be a number.', typeof width === 'number'));
      (false && !(position === 'left' || position === 'right') && Ember.assert('Position must be either \'left\' or \'right\'.', position === 'left' || position === 'right'));
      if (type === 'menuItem' && index === -1) {
        /*
          If the index is -1 that means the specific menu item hasnt been
          rendered yet or it isn't found.
         */
        result = {};
      } else {
        result = this[type](open, width, position === 'right', index);
      }
      if (type === 'menu') {
        Ember.assign(result, {
          width: "".concat(width, "px")
        });
      }
      return (0, _cssStringify.default)(result);
    }
  });
  Animation.reopenClass({
    __isAnimation__: true
  });
  var _default = Animation;
  _exports.default = _default;
});