define("ember-cli-intercom-messenger/services/intercom", ["exports", "intercom"], function (_exports, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getWithDefault = Ember.getWithDefault;
  var _default = Ember.Service.extend({
    api: _intercom.default,
    appId: Ember.computed.reads('settings.appId'),
    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    intercomSettings: Ember.computed(function () {
      return {
        app_id: Ember.get(this, 'appId')
      };
    }),
    settings: Ember.computed(function () {
      var config = Ember.get(this, 'config');
      return getWithDefault(config, 'intercom-messenger', {});
    }),
    boot: function boot() {
      var bootConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var intercomSettings = Ember.get(this, 'intercomSettings');
      bootConfig = Ember.assign(intercomSettings, bootConfig);
      Ember.get(this, 'api')('boot', bootConfig);
    },
    shutdown: function shutdown() {
      Ember.get(this, 'api')('shutdown');
    },
    update: function update() {
      if (arguments.length === 0) {
        Ember.get(this, 'api')('update');
      } else {
        Ember.get(this, 'api')('update', arguments[0]);
      }
    }
  });
  _exports.default = _default;
});