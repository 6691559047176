define("ember-radio-button/components/labeled-radio-button", ["exports", "ember-radio-button/templates/components/labeled-radio-button"], function (_exports, _labeledRadioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'label',
    layout: _labeledRadioButton.default,
    attributeBindings: ['for'],
    classNameBindings: ['_checkedClass'],
    classNames: ['ember-radio-button'],
    defaultLayout: null,
    // ie8 support

    checkedClass: 'checked',
    _checkedClass: Ember.computed('checked', 'checkedClass', function () {
      return this.get('checked') ? this.get('checkedClass') : '';
    }),
    checked: Ember.computed('groupValue', 'value', function () {
      return Ember.isEqual(this.get('groupValue'), this.get('value'));
    }).readOnly(),
    'for': Ember.computed.readOnly('radioId'),
    actions: {
      innerRadioChanged: function innerRadioChanged(value) {
        if (typeof this.get('changed') === 'function') {
          return this.get('changed')(value);
        }
        this.sendAction('changed', value);
      }
    }
  });
  _exports.default = _default;
});