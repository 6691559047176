define("ember-one-way-controls/components/one-way-textarea", ["exports", "ember-one-way-controls/components/one-way-input"], function (_exports, _oneWayInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _oneWayInput.default.extend({
    tagName: 'textarea',
    init: function init() {
      this._super.apply(this, arguments);

      // We need to unset type, otherwise it will try to set it
      // on the element, which results in an error on textarea.
      this.set('type', undefined);
    }
  });
  _exports.default = _default;
});