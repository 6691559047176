define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Yt1dwZXG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"slide\"],[[30,[36,0],[\"swiper-slide\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"hasPagination\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"swiper-pagination\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"hasNavigation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[32,0,[\"nextElClass\"]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"prevElClass\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs"
    }
  });
  _exports.default = _default;
});