define("ember-cli-pagination/validate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validate = Ember.Object.extend();
  Validate.reopenClass({
    internalErrors: [],
    internalError: function internalError(str, obj) {
      this.internalErrors.push(str);
      console.warn(str);
      if (obj) {
        console.warn(obj);
      }
    },
    getLastInternalError: function getLastInternalError() {
      return this.internalErrors[this.internalErrors.length - 1];
    }
  });
  var _default = Validate;
  _exports.default = _default;
});