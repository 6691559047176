define("ember-one-way-controls/helpers/one-way-select/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var emberArray = Ember.A,
    Helper = Ember.Helper,
    isArray = Ember.isArray,
    isPresent = Ember.isPresent,
    get = Ember.get;
  function contains(_ref) {
    var _ref2 = _slicedToArray(_ref, 4),
      haystack = _ref2[0],
      needle = _ref2[1],
      valuePath = _ref2[2],
      targetPath = _ref2[3];
    if (isArray(haystack)) {
      haystack = emberArray(haystack);
      if (valuePath) {
        haystack = targetPath ? haystack : haystack.mapBy(valuePath);
        return emberArray(haystack).includes(get(needle, valuePath));
      } else {
        return haystack.includes(needle);
      }
    } else {
      if (valuePath && isPresent(haystack) && isPresent(needle)) {
        haystack = targetPath ? haystack : get(haystack, valuePath);
        return haystack === get(needle, valuePath);
      } else {
        return haystack === needle;
      }
    }
  }
  var _default = Helper.helper(contains);
  _exports.default = _default;
});