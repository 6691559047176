define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r/RM1rSA",
    "block": "{\"symbols\":[\"checkbox\",\"index\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1,[\"option\"]],[32,1,[\"isSelected\"]],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[32,1,[\"isSelected\"]],[35,0]]]]],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"disabled\",\"input\",\"if\",\"checkboxes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs"
    }
  });
  _exports.default = _default;
});