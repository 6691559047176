define("ember-inputmask/components/one-way-zip-code-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * `{{one-way-zip-code-mask}}` component.
   *
   * Displays an input that masks a US ZIP code.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * @param {boolean} fullCode Allows users to optionally enter the full ZIP+4 area code.e
   */
  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('fullCode'),
    /**
     * Allows users to optionally enter the full ZIP+4 area code.
     */
    fullCode: false,
    /**
     * @override
     */
    mask: Ember.computed('fullCode', function () {
      if (Ember.get(this, 'fullCode')) {
        return '99999[-9999]';
      }
      return '99999';
    })
  });
  _exports.default = _default;
});