define("ember-highlight/components/highlight-terms/component", ["exports", "ember-highlight/components/highlight-terms/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var HighlightTerm = Ember.Component.extend({
    layout: _template.default,
    classNames: ['highlight-terms'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var term = this.get('term');
      var options = this.getProperties('caseSensitive', 'wordsOnly');
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.unhighlight();
        this.highlight(term, options);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unhighlight();
    },
    highlight: function highlight(term, options) {
      if (term) {
        if (Array.isArray(term)) {
          term = term.reduce(function (all, item) {
            if (item !== undefined) {
              if (Array.isArray(item)) {
                var _all;
                all = (_all = all).concat.apply(_all, _toConsumableArray(item));
              } else {
                all.push(item);
              }
            }
            return all;
          }, []);
        }
        if (this.$()) {
          this.$().highlight(term, options);
        }
      }
    },
    unhighlight: function unhighlight() {
      var $el = this.$();
      if ($el && typeof $el.unhighlight === 'function') {
        this.$().unhighlight();
      }
    }
  });
  HighlightTerm.reopenClass({
    positionalParams: 'term'
  });
  var _default = HighlightTerm;
  _exports.default = _default;
});