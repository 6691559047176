define("ember-window-mock/-private/services/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = function _default() {
    return window;
  };
  _exports.default = _default;
});