define("ember-basic-dropdown/components/basic-dropdown/content-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    attributeBindings: ['style', 'dir']
  });
  _exports.default = _default;
});