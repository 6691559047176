define("ember-burger-menu/animations/scale-rotate", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'scale-rotate',
    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? "translate3d(-100px, 0, -".concat(width * 2, "px) rotateY(20deg)") : "translate3d(100px, 0, -".concat(width * 2, "px) rotateY(-20deg)") : ''
      };
    }
  });
  _exports.default = _default;
});