define("ember-burger-menu/utils/closest", ["exports", "matches-selector"], function (_exports, _matchesSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = closest;
  function closest(el, selector, checkSelf) {
    var parent = checkSelf ? el : el.parentNode;
    while (parent && parent !== document) {
      if ((0, _matchesSelector.default)(parent, selector)) {
        return parent;
      } else {
        parent = parent.parentNode;
      }
    }
  }
});