define("ember-enum/utils/enum", ["exports", "ember-data/attr", "ember-enum/enum"], function (_exports, _attr, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = function _default() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? [] : _ref$options,
      _ref$defaultValue = _ref.defaultValue,
      _defaultValue = _ref$defaultValue === void 0 ? null : _ref$defaultValue;
    return (0, _attr.default)('enum', {
      options: options,
      defaultValue: function defaultValue() {
        return _enum.default.create({
          options: options,
          value: _defaultValue
        });
      }
    });
  };
  _exports.default = _default;
});