define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q9S9JRXt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,8],[[35,4,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,7]],[[\"extra\",\"option\",\"select\"],[[30,[36,5],[[35,6]],null],[30,[36,5],[[35,4,[\"selected\"]]],null],[30,[36,5],[[35,4]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-selected-item\"],[12],[18,1,[[35,4,[\"selected\"]],[35,4]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,8],[[30,[36,11],[[35,10],[30,[36,9],[[35,4,[\"disabled\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-clear-btn\"],[15,\"onmousedown\",[30,[36,3],[[32,0],\"clear\"],null]],[15,\"ontouchstart\",[30,[36,3],[[32,0],\"clear\"],null]],[12],[2,\"×\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[35,1]],[[\"placeholder\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"ember-power-select-status-icon\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"placeholderComponent\",\"component\",\"action\",\"select\",\"readonly\",\"extra\",\"selectedItemComponent\",\"if\",\"not\",\"allowClear\",\"and\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });
  _exports.default = _default;
});