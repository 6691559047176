define("ember-changeset/utils/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isObject;
  function isObject(val /*: mixed */) {
    return Ember.typeOf(val) === 'object' || Ember.typeOf(val) === 'instance';
  }
});