define("ember-burger-menu/animations/push-rotate", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'push-rotate',
    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? "translate3d(-".concat(width * 0.85, "px, 0, ").concat(width * 1.3, "px) rotateY(15deg)") : "translate3d(".concat(width, "px, 0, 0) rotateY(-15deg)") : ''
      };
    }
  });
  _exports.default = _default;
});