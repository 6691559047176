define("ember-cli-pagination/page-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    getPage: function getPage() {
      return parseInt(this.get('page') || 1);
    },
    getPerPage: function getPerPage() {
      return parseInt(this.get('perPage'));
    }
  });
  _exports.default = _default;
});