define("ember-enum/enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmberObject = Ember.Object,
    _Ember$String = Ember.String,
    camelize = _Ember$String.camelize,
    capitalize = _Ember$String.capitalize,
    on = Ember.on,
    observer = Ember.observer,
    equal = Ember.computed.equal;
  var _default = EmberObject.extend({
    options: [],
    value: null,
    _defineBooleanMethods: on('init', observer('options.[]', function () {
      var _this = this;
      this.get('options').forEach(function (option) {
        _this.set("is".concat(capitalize(camelize(option))), equal('value', option));
      });
    })),
    toString: function toString() {
      return this.get('value');
    }
  });
  _exports.default = _default;
});