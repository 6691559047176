define("ember-inputmask/components/one-way-ssn-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * `{{one-way-ssn-mask}}` component
   *  Displays an input that masks SSN format
   */
  var _default = _oneWayInputMask.default.extend({
    /**
     * @override
     */
    mask: '999-99-9999'
  });
  _exports.default = _default;
});