define("ember-one-way-controls/components/one-way-search", ["exports", "ember-one-way-controls/components/one-way-input"], function (_exports, _oneWayInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _oneWayInput.default.extend({
    type: 'search'
  });
  _exports.default = _default;
});