define("ember-cli-clipboard/components/copy-button", ["exports", "ember-cli-clipboard/templates/components/copy-button"], function (_exports, _copyButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _copyButton.default,
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type', 'disabled', 'aria-label', 'title'],
    /**
     * @property {Array} clipboardEvents - events supported by clipboard.js
     */
    clipboardEvents: ['success', 'error'],
    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',
    /**
     * @property {Boolean} disabled - disabled state for button element
     */
    disabled: false,
    /**
     * If true - scope event listener to this element
     * If false - scope event listener to document.body (clipboardjs)
     * @property {Boolean} delegateClickEvent
     */
    delegateClickEvent: true,
    didInsertElement: function didInsertElement() {
      var _this = this;
      var clipboard;
      if (!Ember.get(this, 'delegateClickEvent')) {
        clipboard = new window.ClipboardJS(this.element);
      } else {
        clipboard = new window.ClipboardJS("#".concat(this.get('elementId')));
      }
      Ember.set(this, 'clipboard', clipboard);
      Ember.get(this, 'clipboardEvents').forEach(function (action) {
        clipboard.on(action, Ember.run.bind(_this, function (e) {
          try {
            if (!_this.get('disabled')) {
              _this.sendAction(action, e);
            }
          } catch (error) {
            Ember.Logger.debug(error.message);
          }
        }));
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, 'clipboard').destroy();
    }
  });
  _exports.default = _default;
});