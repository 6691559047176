define("ember-radio-button/components/radio-button", ["exports", "ember-radio-button/templates/components/radio-button"], function (_exports, _radioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    layout: _radioButton.default,
    // value - passed in, required, the value for this radio button
    // groupValue - passed in, required, the currently selected value

    // optionally passed in:
    // disabled - boolean
    // required - boolean
    // name - string
    // radioClass - string
    // radioId - string
    // ariaLabelledby - string
    // ariaDescribedby - string

    // polyfill hasBlock for ember versions < 1.13
    hasBlock: Ember.computed.bool('template').readOnly(),
    joinedClassNames: Ember.computed('classNames', function () {
      var classNames = this.get('classNames');
      if (classNames && classNames.length && classNames.join) {
        return classNames.join(' ');
      }
      return classNames;
    }),
    // is this needed here or just on radio-button-input?
    defaultLayout: null,
    // ie8 support

    checkedClass: 'checked',
    checked: Ember.computed('groupValue', 'value', function () {
      return Ember.isEqual(this.get('groupValue'), this.get('value'));
    }).readOnly(),
    actions: {
      changed: function changed(newValue) {
        if (typeof this.get('changed') === 'function') {
          return this.get('changed')(newValue);
        }
        this.sendAction('changed', newValue);
      }
    }
  });
  _exports.default = _default;
});