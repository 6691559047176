define("ember-filestack/components/ember-filestack-picker/component", ["exports", "ember-filestack/components/ember-filestack-picker/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
    scheduleOnce = Ember.run.scheduleOnce,
    service = Ember.inject.service;
  var _default = Ember.Component.extend({
    layout: _template.default,
    filestack: service(),
    actions: {
      handleSelection: function handleSelection(data) {
        if (this.get('onSelection')) {
          this.get('onSelection')(data);
        }
      },
      handleError: function handleError(data) {
        if (data.code === 101 && this.get('onClose')) {
          this.get('onClose')();
        } else if (this.get('onError')) {
          this.get('onError')(data);
        }
      },
      handleClose: function handleClose() {
        var oc = this.get('onClose');
        if (oc) {
          oc();
        }
      }
    },
    getCallClose: function getCallClose() {
      var _this = this;
      return function () {
        _this.send('handleClose');
      };
    },
    onSelection: null,
    onError: null,
    onClose: null,
    options: {},
    openFilepicker: on('didInsertElement', function () {
      scheduleOnce('afterRender', this, function () {
        var _this2 = this;
        this.get('filestack.promise').then(function (filestack) {
          var options = _this2.get('options') || {};
          options['onClose'] = options['onClose'] || _this2.getCallClose();
          filestack.pick(options).then(function (data) {
            _this2.send('handleSelection', data);
          }).catch(function (data) {
            _this2.send('handleError', data);
          });
        });
      });
    })
  });
  _exports.default = _default;
});