define("ember-cli-pagination/components/page-numbers", ["exports", "ember-cli-pagination/util", "ember-cli-pagination/lib/page-items", "ember-cli-pagination/validate", "ember-cli-pagination/templates/components/page-numbers"], function (_exports, _util, _pageItems, _validate, _pageNumbers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _pageNumbers.default,
    currentPage: Ember.computed.alias("content.page"),
    totalPages: Ember.computed.alias("content.totalPages"),
    hasPages: Ember.computed.gt('totalPages', 1),
    watchInvalidPage: Ember.observer("content", function () {
      var _this = this;
      var c = this.get('content');
      if (c && c.on) {
        c.on('invalidPage', function (e) {
          _this._runAction('invalidPageAction', e);
        });
      }
    }),
    // only run if a closure action has been passed
    _runAction: function _runAction(key) {
      var action = Ember.get(this, key);
      if (typeof action === 'function') {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        action.apply(void 0, args);
      }
    },
    truncatePages: true,
    numPagesToShow: 10,
    validate: function validate() {
      if (_util.default.isBlank(this.get('currentPage'))) {
        _validate.default.internalError("no currentPage for page-numbers");
      }
      if (_util.default.isBlank(this.get('totalPages'))) {
        _validate.default.internalError('no totalPages for page-numbers');
      }
    },
    pageItemsObj: Ember.computed(function () {
      var result = _pageItems.default.create({
        parent: this
      });
      Ember.defineProperty(result, 'currentPage', Ember.computed.alias("parent.currentPage"));
      Ember.defineProperty(result, 'totalPages', Ember.computed.alias("parent.totalPages"));
      Ember.defineProperty(result, 'truncatePages', Ember.computed.alias("parent.truncatePages"));
      Ember.defineProperty(result, 'numPagesToShow', Ember.computed.alias("parent.numPagesToShow"));
      Ember.defineProperty(result, 'showFL', Ember.computed.alias("parent.showFL"));
      return result;
    }),
    pageItems: Ember.computed("pageItemsObj.pageItems", "pageItemsObj", function () {
      this.validate();
      return this.get("pageItemsObj.pageItems");
    }),
    canStepForward: Ember.computed("currentPage", "totalPages", function () {
      var page = Number(this.get("currentPage"));
      var totalPages = Number(this.get("totalPages"));
      return page < totalPages;
    }),
    canStepBackward: Ember.computed("currentPage", function () {
      var page = Number(this.get("currentPage"));
      return page > 1;
    }),
    actions: {
      pageClicked: function pageClicked(number) {
        _util.default.log("PageNumbers#pageClicked number " + number);
        this.set("currentPage", number);
        this._runAction('action', number);
      },
      incrementPage: function incrementPage(num) {
        var currentPage = Number(this.get("currentPage")),
          totalPages = Number(this.get("totalPages"));
        if (currentPage === totalPages && num === 1) {
          return false;
        }
        if (currentPage <= 1 && num === -1) {
          return false;
        }
        this.incrementProperty('currentPage', num);
        var newPage = this.get('currentPage');
        this._runAction('action', newPage);
      }
    }
  });
  _exports.default = _default;
});