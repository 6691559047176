define("ember-resource-metadata/serializer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    _resourceMetadata: Ember.inject.service('resource-metadata'),
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var service = this.get('_resourceMetadata');
      var meta = service.peek(snapshot);
      if (meta) {
        json.data.meta = Ember.assign({}, json.data.meta, meta);
      }
      return json;
    }
  });
  _exports.default = _default;
});