define("ember-burger-menu/animations/scale-down", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'scale-down',
    outlet: function outlet(open, width) {
      return {
        transform: open ? "translate3d(0, 0, -".concat(width, "px)") : ''
      };
    }
  });
  _exports.default = _default;
});