define("ember-lifeline/utils/get-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTask;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function getTask(obj, taskOrName, taskName) {
    var type = _typeof(taskOrName);
    var task;
    if (type === 'function') {
      task = taskOrName;
    } else if (type === 'string') {
      task = obj[taskOrName];
      if (typeof task !== 'function') {
        throw new TypeError("The method name '".concat(taskOrName, "' passed to ").concat(taskName, " does not resolve to a valid function."));
      }
    } else {
      throw new TypeError("You must pass a task function or method name to '".concat(taskName, "'."));
    }
    return task;
  }
});