define("ember-cli-pagination/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Util = Ember.Object.extend();
  Util.reopenClass({
    log: function log() {},
    isBlank: function isBlank(obj) {
      if (obj === 0) {
        return false;
      }
      return !obj || obj === "";
    },
    keysOtherThan: function keysOtherThan(params, excludeKeys) {
      excludeKeys = Ember.A(excludeKeys);
      var res = [];
      for (var key in params) {
        if (!excludeKeys.includes(key)) {
          res.push(key);
        }
      }
      return res;
    },
    paramsOtherThan: function paramsOtherThan(params, excludeKeys) {
      var res = {};
      var keys = this.keysOtherThan(params, excludeKeys);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var val = params[key];
        res[key] = val;
      }
      return res;
    },
    mergeHashes: function mergeHashes(a, b) {
      var res = {};
      var val;
      var key;
      for (key in a) {
        val = a[key];
        res[key] = val;
      }
      for (key in b) {
        val = b[key];
        res[key] = val;
      }
      return res;
    },
    isFunction: function isFunction(obj) {
      return typeof obj === 'function';
    },
    getHashKeyForValue: function getHashKeyForValue(hash, targetVal) {
      for (var k in hash) {
        var val = hash[k];
        if (val === targetVal) {
          return k;
        } else if (Util.isFunction(targetVal) && targetVal(val)) {
          return k;
        }
      }
      return undefined;
    }
  });
  var _default = Util;
  _exports.default = _default;
});