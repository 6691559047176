define("ember-radio-button/templates/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V0+Ger30",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,15],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[31,[\"ember-radio-button \",[30,[36,15],[[35,14],[35,13]],null],\" \",[34,12]]]],[15,\"for\",[34,9]],[12],[2,\"\\n    \"],[1,[30,[36,11],null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0],\"changed\"]]]],[2,\"\\n\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,11],null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0],\"changed\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ariaDescribedby\",\"ariaLabelledby\",\"value\",\"groupValue\",\"tabindex\",\"required\",\"name\",\"disabled\",\"autofocus\",\"radioId\",\"radioClass\",\"radio-button-input\",\"joinedClassNames\",\"checkedClass\",\"checked\",\"if\"]}",
    "meta": {
      "moduleName": "ember-radio-button/templates/components/radio-button.hbs"
    }
  });
  _exports.default = _default;
});