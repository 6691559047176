define("ember-cli-states-select/components/states-select", ["exports", "ember-cli-states-select/templates/components/states-select", "ember-cli-states-select/utils/lists"], function (_exports, _statesSelect, _lists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    Component = Ember.Component;
  var _default = Component.extend({
    provinceList: _lists.provinceList,
    stateList: _lists.stateList,
    "show-canada": false,
    layout: _statesSelect.default,
    tagName: "",
    onlyDisplayStateCode: false,
    actions: {
      selected: function selected(selectedValue) {
        set(this, "value", selectedValue);
      },
      blur: function blur() {
        if (this.attrs.onBlur != undefined) {
          this.attrs.onBlur();
        }
      }
    }
  });
  _exports.default = _default;
});