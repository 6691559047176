define("ember-master-tab/consts", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tabIdKey = _exports.tabId = _exports.shouldInvalidateMasterTabKey = _exports.namespace = void 0;
  var namespace = 'ember-master-tab:';
  _exports.namespace = namespace;
  var tabIdKey = "".concat(namespace, "tab-id");
  _exports.tabIdKey = tabIdKey;
  var shouldInvalidateMasterTabKey = "".concat(namespace, "should-invalidate-master-tab");
  _exports.shouldInvalidateMasterTabKey = shouldInvalidateMasterTabKey;
  var tabId = (0, _emberUuid.v4)();
  _exports.tabId = tabId;
});