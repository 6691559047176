define("ember-radio-button/components/radio-button-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    // value - required
    // groupValue - required

    // autofocus - boolean
    // disabled - optional
    // name - optional
    // required - optional
    // radioClass - string
    // radioId - string
    // tabindex - number
    // ariaLabelledby - string
    // ariaDescribedby - string

    defaultLayout: null,
    // ie8 support

    attributeBindings: ['autofocus', 'checked', 'disabled', 'name', 'required', 'tabindex', 'type', 'value', 'ariaLabelledby:aria-labelledby', 'ariaDescribedby:aria-describedby', 'checked:aria-checked'],
    checked: Ember.computed('groupValue', 'value', function () {
      return Ember.isEqual(this.get('groupValue'), this.get('value'));
    }).readOnly(),
    sendChangedAction: function sendChangedAction() {
      var value = this.get('value');
      if (typeof this.get('changed') === 'function') {
        return this.get('changed')(value);
      }
      this.sendAction('changed', value);
    },
    change: function change() {
      var value = this.get('value');
      var groupValue = this.get('groupValue');
      if (groupValue !== value) {
        this.set('groupValue', value); // violates DDAU
        Ember.run.once(this, 'sendChangedAction');
      }
    }
  });
  _exports.default = _default;
});