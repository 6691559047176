define("ember-tooltips/templates/components/ember-tooltip-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ce7UXNkx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"_awaitingTooltipElementRendered\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"_shouldRenderContent\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,0,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"_renderElement\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,0,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-in-el-null\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs"
    }
  });
  _exports.default = _default;
});