define("ember-burger-menu/animations/squeeze", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'squeeze',
    outlet: function outlet(open, width, right) {
      return {
        marginLeft: open && !right ? "".concat(width, "px") : 0,
        marginRight: open && right ? "".concat(width, "px") : 0
      };
    }
  });
  _exports.default = _default;
});