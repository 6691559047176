define("ember-burger-menu/components/bm-outlet", ["exports", "ember-burger-menu/templates/components/bm-outlet", "ember-burger-menu/computed/style-for"], function (_exports, _bmOutlet, _styleFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _bmOutlet.default,
    classNames: ['bm-outlet'],
    attributeBindings: ['style'],
    state: null,
    style: (0, _styleFor.default)('outlet').readOnly()
  });
  _exports.default = _default;
});