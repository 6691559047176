define("ember-one-way-controls/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JVqqUFh+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"option\"],[15,2,[30,[36,3],[[35,4],[30,[36,2],[[35,0],[35,4]],null],[35,0]],null]],[15,\"selected\",[30,[36,11],[[35,10],[35,0],[35,4],[35,1]],null]],[12],[2,\"\\n\"],[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,9],[[35,8]],[[\"option\",\"index\",\"groupIndex\"],[[35,0],[35,7],[35,6]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,0],[35,5]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,0],[35,4]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,0],[35,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"optionTargetPath\",\"get\",\"if\",\"optionValuePath\",\"optionLabelPath\",\"groupIndex\",\"index\",\"optionComponent\",\"component\",\"selected\",\"one-way-select/contains\"]}",
    "meta": {
      "moduleName": "ember-one-way-controls/templates/components/one-way-select/option.hbs"
    }
  });
  _exports.default = _default;
});