define("ember-enum/transforms/enum", ["exports", "ember-data/transform", "ember-enum/enum"], function (_exports, _transform, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var typeOf = Ember.typeOf;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$options = _ref.options,
        options = _ref$options === void 0 ? [] : _ref$options,
        _ref$defaultValue = _ref.defaultValue,
        defaultValue = _ref$defaultValue === void 0 ? null : _ref$defaultValue;
      return _enum.default.create({
        value: serialized || defaultValue,
        options: options
      });
    },
    serialize: function serialize(deserialized) {
      if (typeOf(deserialized) === 'instance') {
        return deserialized.get('value');
      } else {
        return deserialized;
      }
    }
  });
  _exports.default = _default;
});