define("liquid-fire/mixins/pausable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    _transitionMap: Ember.inject.service('liquid-fire-transitions'),
    _initializeLiquidFirePauseable: Ember.on('init', function () {
      this._lfDefer = [];
    }),
    pauseLiquidFire: function pauseLiquidFire() {
      var context = this.nearestWithProperty('_isLiquidChild');
      if (context) {
        var def = new Ember.RSVP.defer();
        var tmap = this.get('_transitionMap');
        tmap.incrementRunningTransitions();
        def.promise.finally(function () {
          return tmap.decrementRunningTransitions();
        });
        this._lfDefer.push(def);
        context._waitForMe(def.promise);
      }
    },
    resumeLiquidFire: Ember.on('willDestroyElement', function () {
      var def = this._lfDefer.pop();
      if (def) {
        def.resolve();
      }
    })
  });
  _exports.default = _default;
});