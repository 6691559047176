define("ember-burger-menu/animations/slide", ["exports", "ember-burger-menu/animations/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    animation: 'slide'
  });
  _exports.default = _default;
});