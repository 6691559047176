define("ember-custom-actions/utils/url-builder", ["exports", "jquery-param"], function (_exports, _jqueryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(url, path, queryParams) {
    var query = (0, _jqueryParam.default)(queryParams);
    var pathUrl = url.charAt(url.length - 1) === '/' ? "".concat(url).concat(path) : "".concat(url, "/").concat(path);
    return query ? "".concat(pathUrl, "?").concat(query) : pathUrl;
  }
});