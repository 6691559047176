define("emberx-select/templates/components/x-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HzJrAe2Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"option\"],[[30,[36,1],[\"x-option\"],[[\"select\",\"register\",\"unregister\"],[[32,0],[30,[36,0],[[32,0],\"registerOption\"],null],[30,[36,0],[[32,0],\"unregisterOption\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "emberx-select/templates/components/x-select.hbs"
    }
  });
  _exports.default = _default;
});