define("ember-burger-menu/templates/components/bm-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "87PAALKW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,10],null,[[\"to\",\"renderInPlace\"],[[30,[36,9],[[35,8],\"-outlet-menu\"],null],[35,7]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bm-menu\"],[15,5,[34,0]],[12],[2,\"\\n    \"],[18,1,[[30,[36,6],null,[[\"item\"],[[30,[36,5],[\"bm-menu-item\"],[[\"tagName\",\"menuItems\",\"dismissOnClick\",\"state\"],[[35,4],[35,3],[35,2],[35,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"style\",\"state\",\"dismissOnItemClick\",\"menuItems\",\"itemTagName\",\"component\",\"hash\",\"renderInPlace\",\"containerId\",\"concat\",\"ember-wormhole\"]}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-menu.hbs"
    }
  });
  _exports.default = _default;
});