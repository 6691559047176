define("ember-data-array-transform/transforms/array", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return Ember.A([]);
      } else {
        return Ember.A(serialized);
      }
    },
    serialize: function serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return Ember.A([]);
      } else {
        return Ember.A(deserialized);
      }
    }
  });
  _exports.default = _default;
});