define("ember-changeset-validations/utils/validation-errors", ["exports", "ember-changeset-validations/utils/get-messages", "ember-get-config"], function (_exports, _getMessages, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildMessage;
  var assert = Ember.assert,
    typeOf = Ember.typeOf;
  var assign = Ember.assign || Ember.merge;
  function buildMessage(key, result) {
    var returnsRaw = Ember.getWithDefault(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    var messages = (0, _getMessages.default)();
    var description = messages.getDescriptionFor(key);
    if (result.message) {
      return result.message;
    }
    var type = result.type,
      value = result.value,
      _result$context = result.context,
      context = _result$context === void 0 ? {} : _result$context;
    if (context.message) {
      var _message = context.message;
      if (typeOf(_message) === 'function') {
        var builtMessage = _message(key, type, value, context);
        assert('Custom message function must return a string', typeOf(builtMessage) === 'string');
        return builtMessage;
      }
      return messages.formatMessage(_message, assign({
        description: description
      }, context));
    }
    var message = Ember.get(messages, type);
    if (returnsRaw) {
      context = assign({}, context, {
        description: description
      });
      return {
        value: value,
        type: type,
        message: message,
        context: context
      };
    } else {
      return messages.formatMessage(message, assign({
        description: description
      }, context));
    }
  }
});