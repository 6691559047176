define("liquid-fire/components/liquid-sync", ["exports", "liquid-fire/templates/components/liquid-sync", "liquid-fire/mixins/pausable"], function (_exports, _liquidSync, _pausable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_pausable.default, {
    tagName: '',
    layout: _liquidSync.default,
    didInsertElement: function didInsertElement() {
      this.pauseLiquidFire();
    },
    actions: {
      ready: function ready() {
        this.resumeLiquidFire();
      }
    }
  });
  _exports.default = _default;
});