define("ember-master-tab/utils", ["exports", "ember-master-tab/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debug = debug;
  function debug() {
    if (console.debug) {
      var _console;
      (_console = console).debug.apply(_console, [_consts.namespace].concat(Array.prototype.slice.call(arguments)));
    } else {
      var _console2;
      (_console2 = console).log.apply(_console2, [_consts.namespace].concat(Array.prototype.slice.call(arguments)));
    }
  }
});