define("ember-cli-intercom-messenger/initializers/ember-cli-intercom-messenger", ["exports", "intercom"], function (_exports, _intercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var config = application.resolveRegistration('config:environment');
    var settings = Ember.get(config, 'intercom-messenger');
    if (Ember.get(settings, 'enabled') === false) {
      return;
    }
    Ember.assert('You must define `ENV.intercom-messenger.appId` in your `config/environment.js` file.', Ember.isPresent(Ember.get(settings, 'appId')));
    (0, _intercom._setup)(config);
  }
  var _default = {
    name: 'ember-cli-intercom-messenger',
    initialize: initialize
  };
  _exports.default = _default;
});