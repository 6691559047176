define("ember-power-select/components/power-select-multiple/trigger", ["exports", "ember-power-select/templates/components/power-select-multiple/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ua = window && window.navigator ? window.navigator.userAgent : '';
  var isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  var isTouchDevice = !!window && 'ontouchstart' in window;
  var _default = Ember.Component.extend({
    tagName: '',
    layout: _trigger.default,
    textMeasurer: Ember.inject.service(),
    _lastIsOpen: false,
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var select = this.get('select');
      this.input = document.getElementById("ember-power-select-trigger-multiple-input-".concat(select.uniqueId));
      var inputStyle = this.input ? window.getComputedStyle(this.input) : null;
      this.inputFont = inputStyle ? "".concat(inputStyle.fontStyle, " ").concat(inputStyle.fontVariant, " ").concat(inputStyle.fontWeight, " ").concat(inputStyle.fontSize, "/").concat(inputStyle.lineHeight, " ").concat(inputStyle.fontFamily) : null;
      var optionsList = document.getElementById("ember-power-select-multiple-options-".concat(select.uniqueId));
      var chooseOption = function chooseOption(e) {
        var selectedIndex = e.target.getAttribute('data-selected-index');
        if (selectedIndex) {
          e.stopPropagation();
          e.preventDefault();
          var _select = _this.get('select');
          var object = _this.selectedObject(_select.selected, selectedIndex);
          _select.actions.choose(object);
        }
      };
      if (isTouchDevice) {
        optionsList.addEventListener('touchstart', chooseOption);
      }
      optionsList.addEventListener('mousedown', chooseOption);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var oldSelect = this.get('oldSelect') || {};
      var select = this.set('oldSelect', this.get('select'));
      if (oldSelect.isOpen && !select.isOpen) {
        Ember.run.scheduleOnce('actions', null, select.actions.search, '');
      }
    },
    // CPs
    triggerMultipleInputStyle: Ember.computed('select.{searchText.length,selected.length}', function () {
      var select = this.get('select');
      Ember.run.scheduleOnce('actions', select.actions.reposition);
      if (!select.selected || Ember.get(select.selected, 'length') === 0) {
        return Ember.String.htmlSafe('width: 100%;');
      } else {
        var textWidth = 0;
        if (this.inputFont) {
          textWidth = this.get('textMeasurer').width(select.searchText, this.inputFont);
        }
        return Ember.String.htmlSafe("width: ".concat(textWidth + 25, "px"));
      }
    }),
    maybePlaceholder: Ember.computed('placeholder', 'select.selected.length', function () {
      if (isIE) {
        return;
      }
      var select = this.get('select');
      return !select.selected || Ember.get(select.selected, 'length') === 0 ? this.get('placeholder') || '' : '';
    }),
    // Actions
    actions: {
      onInput: function onInput(e) {
        var action = this.get('onInput');
        if (action && action(e) === false) {
          return;
        }
        this.get('select').actions.open(e);
      },
      onKeydown: function onKeydown(e) {
        var _this$getProperties = this.getProperties('onKeydown', 'select'),
          onKeydown = _this$getProperties.onKeydown,
          select = _this$getProperties.select;
        if (onKeydown && onKeydown(e) === false) {
          e.stopPropagation();
          return false;
        }
        if (e.keyCode === 8) {
          e.stopPropagation();
          if (Ember.isBlank(e.target.value)) {
            var lastSelection = select.selected[select.selected.length - 1];
            if (lastSelection) {
              select.actions.select(this.get('buildSelection')(lastSelection, select), e);
              if (typeof lastSelection === 'string') {
                select.actions.search(lastSelection);
              } else {
                var searchField = this.get('searchField');
                (false && !(searchField) && Ember.assert('`{{power-select-multiple}}` requires a `searchField` when the options are not strings to remove options using backspace', searchField));
                select.actions.search(Ember.get(lastSelection, searchField));
              }
              select.actions.open(e);
            }
          }
        } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
          // Keys 0-9, a-z or SPACE
          e.stopPropagation();
        }
      }
    },
    // Methods
    selectedObject: function selectedObject(list, index) {
      if (list.objectAt) {
        return list.objectAt(index);
      } else {
        return Ember.get(list, index);
      }
    }
  });
  _exports.default = _default;
});