define("ember-cli-intercom-messenger/components/intercom-messenger", ["exports", "ember-cli-intercom-messenger/templates/components/intercom-messenger"], function (_exports, _intercomMessenger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IntercomMessengerComponent = Ember.Component.extend({
    intercom: Ember.inject.service(),
    bootConfig: {},
    layout: _intercomMessenger.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var bootConfig = Ember.get(this, 'bootConfig');
      var intercom = Ember.get(this, 'intercom');
      Ember.run.scheduleOnce('afterRender', intercom, 'boot', bootConfig);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var intercom = Ember.get(this, 'intercom');
      Ember.run.scheduleOnce('afterRender', intercom, 'shutdown');
    }
  });
  IntercomMessengerComponent.reopenClass({
    positionalParams: ['bootConfig']
  });
  var _default = IntercomMessengerComponent;
  _exports.default = _default;
});